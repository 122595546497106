export const saveProgress = (topicId, progressData) => {
    if(!progressData ||typeof progressData!=='object'){
        console.error("invalid progress data: ", progressData);
        return;
    }

    let userProgress;

    try{
        userProgress = JSON.parse(localStorage.getItem('userProgress') || "{}");
    } catch (error)
    {
        console.error("error parsing data from local storage: ",error);
        userProgress={};
    }

     // Update or add the progress for the specified topic
    userProgress[topicId] = { ...userProgress[topicId], ...progressData }; // Merge new data with existing

    try{
        localStorage.setItem('userProgress', JSON.stringify(userProgress));
    } catch (error)
    {
        console.error("Error saving userProgress to local storage: ", error);
    }
    
};

export const getProgress = (topicId) => 
{
    try {
        const userProgress = JSON.parse(localStorage.getItem('userProgress')) || {};
        return userProgress[topicId] || {quizProgress: 0};
    } catch (error)
    {
        console.error("error parsing userProgress from local storage: ", error);
        return {quizProgress:0};
    }
};

export const clearProgress = () => {
    localStorage.removeItem('userProgress');
};

export const saveTopScore = (theme, score) => {
    let topScores;
    try {
        topScores = JSON.parse(localStorage.getItem('topScore')) || {};
    } catch (error) {
        console.error("Error parsing topScore from local storage:", error);
        topScores = {};
    }

    // Only update if score is higher than the existing top score for the theme
    if (!topScores[theme] || score > topScores[theme].topScore) {
        topScores[theme] = { topScore: score };
        console.log(`Saving new top score for theme "${theme}": ${score}`);
        try {
            localStorage.setItem('topScore', JSON.stringify(topScores));
        } catch (error) {
            console.error("Error saving top score to local storage:", error);
        }
    } else {
        console.log(`Top score for theme "${theme}" is already higher or equal. Not updating.`);
    }
};

export const getTopScore = (theme) => {
    try {
        const topScores = JSON.parse(localStorage.getItem('topScore')) || {};
        console.log("Top scores object in localStorage:", topScores);
        console.log(`Top score retrieved for theme "${theme}":`, topScores[theme]?.topScore);

        // Return the top score for the theme or 0 if it doesn't exist
        return topScores[theme]?.topScore || 0;
    } catch (error) {
        console.error("Error trying to get the top score:", error);
        return 0;
    }
};

export const saveWordProgress = (word, isCorrect)=> {
    let wordProgress = JSON.parse(localStorage.getItem('wordProgress')) || {};
    wordProgress[word] = isCorrect;
    localStorage.setItem('wordProgress',JSON.stringify(wordProgress));
};

export const getWordProgress = () => {
    return JSON.parse(localStorage.getItem('wordProgress')) || {};
};