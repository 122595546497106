import React, { useState, useEffect } from "react";
import "./css/Flashcards.css";
import { useLocation, useNavigate } from "react-router-dom";
import { saveProgress, getProgress } from "./progressUtils";
import topics from "./data/fullVocabStructuredWithType.json";

const Flashcards = () => {
  const [currentCard, setCurrentCard] = useState(0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [vocab, setVocab] = useState([]);
  const [themeName, setThemeName] = useState("Unknown Topic");

  const location = useLocation();
  const navigate = useNavigate();

  const subtopicID = location.state?.subtopicID;
  const topicId = subtopicID; // Use subtopicID for progress tracking
  const { quizScore, flashcardsCompleted } = getProgress(topicId);

  useEffect(() => {
    // Debug topics structure
    console.log("Topics data:", topics);

    if (!subtopicID) {
      console.error("Subtopic ID not provided.");
      return;
    }

    if (!Array.isArray(topics)) {
      console.error("Invalid topics data.");
      return;
    }

    // Traverse the JSON to find the subtopic
    const subtopic = topics
      .flatMap((type) =>
        Array.isArray(type.themes)
          ? type.themes.flatMap((theme) =>
              Array.isArray(theme.subtopics) ? theme.subtopics : []
            )
          : []
      )
      .find((sub) => sub.subtopicID === subtopicID);

    if (subtopic) {
      setVocab(subtopic.vocabulary || []);
      setThemeName(subtopic.subtopic || "Unknown Topic");
    } else {
      console.error(`Subtopic not found for ID: ${subtopicID}`);
    }
  }, [subtopicID]);

  useEffect(() => {
    if (vocab.length > 0) {
      const percentageCompleted = Math.floor(((currentCard + 1) / vocab.length) * 100);
      saveProgress(topicId, { quizScore, flashcardsCompleted: percentageCompleted });
    }
  }, [currentCard, quizScore, vocab.length, topicId]);

  if (!subtopicID) {
    return (
      <div>
        <h2>Error: Subtopic ID not provided.</h2>
        <button onClick={() => navigate("/")}>Back to Home</button>
      </div>
    );
  }

  if (vocab.length === 0) {
    return (
      <div>
        <h2>No flashcards available for this subtopic</h2>
        <button onClick={() => navigate("/")}>Back to Home</button>
      </div>
    );
  }

  const handleFlip = () => {
    setIsFlipped(!isFlipped);
  };

  const handleNextCard = () => {
    if (currentCard < vocab.length - 1) {
      setIsFlipped(false);
      setCurrentCard(currentCard + 1);
    } else {
      alert("Good job, Flashcards complete!");
      navigate("/");
    }
  };

  const handlePreviousCard = () => {
    if (currentCard > 0) {
      setIsFlipped(false);
      setCurrentCard(currentCard - 1);
    }
  };

  const currentWord = vocab[currentCard];

  return (
    <div className="flashcards">
      <h2>Flashcards Mode: {themeName}</h2>
      <p>Progress: {Math.floor(((currentCard + 1) / vocab.length) * 100)}%</p>

      <div className={`card ${isFlipped ? "flipped" : ""}`} onClick={handleFlip}>
        {isFlipped ? (
          <p>{currentWord.english}</p> // English translation
        ) : (
          <p>{currentWord.spanish}</p> // Spanish word
        )}
      </div>

      <div className="controls">
        <button onClick={handlePreviousCard} disabled={currentCard === 0}>
          Previous
        </button>
        <button onClick={handleFlip}>Flip</button>
        <button onClick={handleNextCard} disabled={currentCard === vocab.length - 1}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Flashcards;
