import React from 'react';
//import topics from './data/FullVocabData';
import topics from './data/fullVocabStructuredWithType.json';
import {useNavigate} from 'react-router-dom';
import './css/TopicsList.css'
import { getProgress,getTopScore } from './progressUtils';


// The TopicList component
const TopicList = () => {

  const navigate = useNavigate();



  // Function to handle the topic selection
  const handleSubTopicClick = (subtopic) => {
    navigate(`/vocabulary/${subtopic.name}`);
  };

  //function to start quiz for a specefic theme
  const startQuiz = (themeName, themeVocabulary) => {
    console.log("themeName:", themeName);
    navigate('/quiz', {state: {vocabulary: themeVocabulary, themeName}});
  };

  const startFlashcards = (themeName, themeVocabulary) => {
    console.log("Themename: ",themeName);
    //console.log("themeVocab for flashcards:", themeVocabulary);
    navigate('flashcards', {state: {vocabulary: themeVocabulary, themeName}});
  };


  return (
    <div className="topic-list">
      <h1>GCSE Spanish Vocabulary Topics</h1>
      {topics.map((type) => (
        <div key={type.typeID} className="type-section">
          <h2>{type.type}</h2>
          {type.themes.map((theme) => {
            const { themeID, theme: themeName, subtopics } = theme;
            const topScore = getTopScore(themeName);

            return (
              <div key={themeID} className="theme">
                <div className="topic-header">
                  <h3>{themeName}</h3>
                  
                </div>
                <ul className='subtopics-list'>
                  {subtopics.map((subtopic) => (
                    <li key={subtopic.subtopicID}  
                     onClick={() => navigate(`/vocabulary/${subtopic.subtopicID}`)}
                     className='subtopic-item' 
                    >
                    <span>  {subtopic.subtopic}</span>
                    <div className='subtopic-buttons'>
                      <button className='quiz-button' onClick={(e)=> {
                          e.stopPropagation(); 
                          navigate('/quiz', { state: { vocabulary: subtopic.vocabulary, themeName: subtopic.subtopic, },});
                        }}>
                        
                        Quiz</button>
                      <button
                          className="flashcards-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigate('/flashcards',{
                              state: {
                                vocabulary: subtopic.vocabulary,
                                themeName: subtopic.subtopic,
                                subtopicID: subtopic.subtopicID,
                              },
                            });
                          } 
                          }
                        >
                          Flashcards
                        </button>
                    
                    </div>
                    </li>
                  ))}
                </ul>
              </div>
            );
          })}
        </div>
      ))}
    </div>
  );
};

export default TopicList;