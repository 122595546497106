import React from 'react';
import './App.css';
import TopicList from './TopicList';
import VocabDetail from './VocabDetail';
import { BrowserRouter as Router, Route,Routes,Link } from 'react-router-dom';
import Quiz from './Quiz';
import Flashcards from './tempFlashcards';

function App() {
  return (
    <Router>
    <div className="App">
      <header className="App-header">
        <h1>GCSE Spanish Revision</h1>
        <nav>
          <Link color='darkGrey' to="/">Home</Link>
          
        </nav>
        <Routes>
          <Route path="/" element={<TopicList/>}/>
          <Route path="/vocabulary/:subtopicID" element={<VocabDetail></VocabDetail>} />
          <Route path="/quiz" element={<Quiz />} />
          <Route path='/flashcards' element={<Flashcards/>} />
        </Routes>
      </header>
    </div>
    </Router>
  );
}

export default App;
