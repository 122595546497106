import React, {useEffect, useState} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './css/TopicsList.css';
import './css/Quiz.css';
import { getTopScore, saveTopScore,saveProgress, getProgress,getWordProgress,saveWordProgress } from './progressUtils';

const Quiz = () => {
    
    const location = useLocation();
    const navigate = useNavigate();
    
    const vocabulary = location.state?.vocabulary || [];
    const themeName = location.state?.themeName || 'Unknown Theme';

    //get word progress from local storage
    const wordProgress = getWordProgress();

    //filter out words the student has answered correctly
    const filteredVocabularly = vocabulary.filter(
        (word) => !wordProgress[word.spanish]
    );

    // shuffle and limit to 20 questions
    const shuffledVocabulary = [...filteredVocabularly].sort(()=>Math.random() -0.5);
    const limitedVocab = shuffledVocabulary.slice(0, Math.min(20,filteredVocabularly.length));

    const [questions, setQuestions] = useState(limitedVocab);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [score, setScore] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
    const [showFeedback, setShowFeedback] = useState(false);
    const [quizComplete, setQuizComplete] = useState(false);

    const question = questions[currentQuestion];
   
    //consts to track the score
    const topicId = themeName;
    const totalQuestions = vocabulary.length;
    useEffect (() => {
        if(totalQuestions>0)
        {
            const quizProgress = Math.floor(((currentQuestion+1)/totalQuestions)*100);
            saveProgress(topicId, {quizProgress});
        }     
    }, [currentQuestion]);

    if (!vocabulary.length) {
        return <p> No vocab found for this theme</p>
    };

    


  //console.log("Vocab:", vocabulary);


   

    const handleAnswerClick = (answer) => {
        setSelectedAnswer(answer);
        setShowFeedback(true);

        const isCorrect = answer === question.english;
        saveWordProgress(question.spanish, isCorrect);

        if (isCorrect)
        {
            setScore(score + 1);
        }
    };

    const handleNextQuestion = () => {
        setShowFeedback(false);
        setSelectedAnswer(null);

        console.log("Next question triggered. Current question index:", currentQuestion);
         // Log the length of vocabulary to check if it’s correct
        console.log("Total questions (vocabulary.length):", vocabulary.length);
        console.log("Total questions (questions.length):", questions.length);
        console.log("Current question index (currentQuestion):", currentQuestion);

        if (currentQuestion + 1 < questions.length)
        {
            console.log("Quiz completed.");
            setCurrentQuestion(currentQuestion + 1);
        } 
        else 
        {
            console.log("Quiz completed.");
            setQuizComplete(true);
            //saveTopScore(topicId, score);

            //navigate('/');
        }
    };

    if(quizComplete)
    {
        return (
            <div className='quiz-complete'>
                <h2>Quiz Complete</h2>
                <p>Your score: {score} out of {questions.length}</p>
                <button onClick={() => window.location.reload()}>Restart Quiz</button>
                <button onClick={() => navigate('/')}>Back to Home</button>
            </div>
        );
    };


     

    const getOptions = (question, vocabulary) => {
        const uniqueOptions = new Set();
        uniqueOptions.add(question.english);

        while (uniqueOptions.size < 4) {
            const randomIndex = Math.floor(Math.random() * vocabulary.length);
            const randomWord = vocabulary[randomIndex].english

            uniqueOptions.add(randomWord);
        }

        return Array.from(uniqueOptions).sort(() => Math.random() - 0.5);
    };

    return (
        <div className='quiz'>
            <h2>Quiz for: {themeName}</h2>
            <p>Score: {score} (out of {currentQuestion+1})</p>
            {/*<p>Progress: {getProgress(topicId).quizProgress}%</p>*/}
            <div className='question'>
                <h3> Translate: 
                    <span className={selectedAnswer && selectedAnswer !== question.english ? 'incorrect-question' : ''}> {question.spanish}</span>
                </h3>
                <div className='options'>
                    {
                        getOptions(question,vocabulary).map((option,index) =>(
                            <button 
                                key={index}
                                onClick={() => handleAnswerClick(option)}
                                disabled={showFeedback}
                            > 
                            {option}
                            </button>    
                        ))
                    }
                </div>
            </div>
            
            {/* Feeback Section */}
            <div className='feedback-container' style={{ opacity: showFeedback ? 1:0}}>
                {showFeedback && (
                    <div className='feedback'>
                        {selectedAnswer === question.english ? ("Correct! you're the best") : 
                        (<>Incorrect you bozo! The correct answer is: <strong style={{color:'red'}}>{question.english}</strong></>)}
                    </div>

                )}

                {showFeedback && (
                    <button 
                        className='next-question-button'
                        style={{ opacity: showFeedback ? 1:0}} 
                        onClick={handleNextQuestion}>Next Question</button>
                )}
            </div>
        </div>        
    );

};

export default Quiz;
    
