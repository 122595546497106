import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import topics from './data/fullVocabStructuredWithType.json';

const VocabDetail = () => {
    const {subtopicID} = useParams();
    const navigate = useNavigate();


    //find the subtopic with the matching name
    let subtopic=null;
    console.log("URL subtopicID:", subtopicID);
    

    for (const type of topics)
    {
        for(const theme of type.themes)
        {
            subtopic = theme.subtopics.find((sub) => String(sub.subtopicID) === subtopicID);
            console.log("Subtopic IDs in JSON:", theme.subtopics.map((sub) => sub.subtopicID));
            if(subtopic) {break};
        }
        if (subtopic) {
            break; // Exit outer loop
          }
    }

    if (!subtopic) {
        return <div>Subtopic not found</div>;
    }


return (
    <div className='vocabulary-detail'>
        <button onClick={() => navigate('/')}>Back to Topic List</button>
        <h2>Vocab for: {subtopic.subtopic}</h2>
        <table>
            <thead>
                <tr style={{ color: '#504f4f'}} >
                    <th >Spanish</th>
                    <th>English</th>
                </tr>
            </thead>
            <tbody>
                {subtopic.vocabulary.map((word,index) =>(
                    <tr key={index}>
                        <td>{word.spanish}</td>
                        <td>{word.english}</td>
                    </tr>
                ))}
            </tbody>
        </table>

    </div>
);
};

export default VocabDetail;